import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import { BUILD_ENV } from './helpers'
import { localStorage } from './local-storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBkxmrKlipRGC52eZEetRGeyeCI-PfK3uk',
  authDomain: 'aivy-requirements-analysis.firebaseapp.com',
  projectId: 'aivy-requirements-analysis',
  storageBucket: 'aivy-requirements-analysis.appspot.com',
  messagingSenderId: '31953545287',
  appId: '1:31953545287:web:e42b4ef11346ecd854a962',
  measurementId: 'G-EHLE67DKQF'
}

const app = initializeApp(firebaseConfig)
const webAnalytics = getAnalytics(app)

export const trackEvent = (eventName, eventParams = {}) => {
  eventParams.career_analysis_id = localStorage.getItem('career-analysis-id')
  eventParams.partner_id = localStorage.getItem('partner-id')
  eventParams.career_id = localStorage.getItem('career-id')

  if (BUILD_ENV !== 'production') {
    console.log('trackEvent', { eventName, eventParams }) // eslint-disable-line
    return
  }

  logEvent(webAnalytics, eventName, eventParams)
}
