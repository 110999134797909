import { useEffect } from 'react'
import { isNumber } from 'lodash'

import example from '../assets/images/normal-distribution-curve/example.png'

import { usePersist } from './use-persist'
import { useSystemContext } from '../context/system-context'
import { IMPORTANCIES, classNames } from '../utils/helpers'
import Typo from '../components/typo'
import { mutation } from '../graphql/api'
import { useSetState } from './use-set-state'
import { localStorage } from '../utils/local-storage'

export const useCategories = ({ analyse }) => {
  const { categories, dimensions } = useSystemContext()

  const [data, setData] = useSetState({
    seen: [],
    estimations: {},
    importancies: {},
    time_needed_in_ms: 0,
    isLoading: true
  })

  useEffect(() => {
    // wait for the analyse
    if (!analyse) return

    const { _temp } = JSON.parse(analyse.input || '{}')

    if (_temp) setData({ ..._temp, isLoading: false })
    else setData({ isLoading: false })
  }, [analyse, setData])

  const updateTempInput = ({ seen, estimations, importancies }) => {
    const _temp = { ...data }

    if (seen) _temp.seen = seen
    if (estimations) _temp.estimations = estimations
    if (importancies) _temp.importancies = importancies

    const now = new Date()
    const last_interaction = new Date(localStorage.getItem('last-interaction'))

    _temp.time_needed_in_ms =
      data.time_needed_in_ms + (now.getTime() - last_interaction.getTime())

    localStorage.setItem('last-interaction', now.toISOString())
    setData({ time_needed_in_ms: _temp.time_needed_in_ms })

    mutation({
      mutation: 'updateCareerAnalyse',
      input: { id: analyse.id, input: JSON.stringify({ _temp }) },
      authMode: 'AWS_IAM'
    })
  }

  const [showInfoIconHint, setShowInfoIconHint] = usePersist({
    key: `info-icon-hint`,
    initialState: true
  })

  const { seen, estimations, importancies, isLoading, time_needed_in_ms } = data

  const result = {
    INTRO_0: {
      key: 'INTRO_0',
      title: 'intro.0.title',
      description: 'intro.0.description'
    },
    INTRO_1: {
      key: 'INTRO_1',
      title: 'intro.1.title',
      description: 'intro.1.description'
    },
    INTRO_2: {
      key: 'INTRO_2',
      title: 'intro.2.title',
      description: 'intro.2.description',
      attention: 'intro.2.attention'
    },
    INTRO_3: {
      key: 'INTRO_3',
      title: 'intro.3.title',
      description: 'intro.3.description',
      description_2: 'intro.3.description_2',
      attention: 'intro.3.attention',
      example
    },
    INTRO_4: {
      key: 'INTRO_4',
      title: 'intro.4.title',
      description: 'intro.4.description',
      ExampleComponent: () => (
        <div className='mt-8 mb-4 flex justify-center'>
          <div className='flex flex-col justify-center py-6 px-8 rounded-2xl bg-white'>
            <Typo type='t2' translate>
              global.importance_question
            </Typo>
            <div className='mt-2 flex items-center'>
              {IMPORTANCIES.map((_, index) => {
                // eslint-disable-next-line yoda
                const filled = 2 >= index

                return (
                  <div
                    key={index}
                    className='w-6 h-6 flex items-center justify-center rounded-full border-2 border-indigo-500 mr-0.5'
                  >
                    <div
                      className={classNames(
                        'w-5 h-5 rounded-full border-2 border-gray-50',
                        filled ? 'bg-indigo-500' : 'bg-gray-50'
                      )}
                    ></div>
                  </div>
                )
              })}
              <Typo type='h4' translate style={{ marginLeft: 8 }}>
                {
                  {
                    '-1': '',
                    0: 'global.importance_unimportant',
                    1: 'global.importance_less_important',
                    2: 'global.importance_important',
                    3: 'global.importance_very_important'
                  }[2]
                }
              </Typo>
            </div>
          </div>
        </div>
      )
    },
    PERSONALITY: {
      ...categories.data['1'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 1)
        .map((key, index) => ({
          key,
          // the first dimension should be seen by default
          seen: index === 0 || seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          // [0, 0.5, 1, 2][2] => index 2 corresponds to the importance value 1 (important)
          importance: isNumber(importancies[key]) ? importancies[key] : 2,
          showInfoIconHint: index === 0 ? showInfoIconHint : false,
          setShowInfoIconHint: index === 0 ? setShowInfoIconHint : () => {},
          ...dimensions.data[key]
        }))
    },
    SKILLS: {
      ...categories.data['2'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 2)
        .map((key) => ({
          key,
          seen: seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          // [0, 0.5, 1, 2][2] => index 2 corresponds to the importance value 1 (important)
          importance: isNumber(importancies[key]) ? importancies[key] : 2,
          showInfoIconHint: false,
          setShowInfoIconHint: () => undefined,
          ...dimensions.data[key]
        }))
    },
    INTERESTS: {
      ...categories.data['3'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 3)
        .map((key) => ({
          key,
          seen: seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          // [0, 0.5, 1, 2][2] => index 2 corresponds to the importance value 1 (important)
          importance: isNumber(importancies[key]) ? importancies[key] : 2,
          showInfoIconHint: false,
          setShowInfoIconHint: () => undefined,
          ...dimensions.data[key]
        }))
    },
    OVERVIEW_INTRO: {
      key: 'OVERVIEW_INTRO',
      title: 'overview_intro.title',
      description: 'overview_intro.description'
    },
    OUTRO: {
      key: 'OUTRO',
      title: 'outro.title',
      description: 'outro.description',
      description_2: 'outro.description_2'
    }
  }

  return {
    isLoading,
    categories: result,
    time_needed_in_ms,
    updateEstimation: ({ key, value }) => {
      const e = { ...estimations, [key]: value }

      setData({ estimations: e })
      updateTempInput({ estimations: e })
    },
    updateImportance: ({ key, value }) => {
      const i = { ...importancies, [key]: value }

      setData({ importancies: i })
      updateTempInput({ importancies: i })
    },
    setSeen: (key) => {
      const s = [...seen.filter((d) => d !== key), key]

      setData({ seen: s })
      updateTempInput({ seen: s })
    }
  }
}
