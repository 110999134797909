import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm' // github flavor markdown support

const normal = { fontWeight: '400' }
const medium = { fontWeight: '500' }
const semibold = { fontWeight: '600' }
const bold = { fontWeight: '700' }

const lineHeight19 = { lineHeight: '19px' }
const lineHeight22 = { lineHeight: '22px' }
const lineHeight26 = { lineHeight: '26px' }
const lineHeight35 = { lineHeight: '35px' }

const uppercase = { textTransform: 'uppercase' }

const h1 = { fontSize: 30, ...semibold, ...lineHeight35 }
const h12 = { fontSize: 24, ...semibold, ...lineHeight26 }
const h2 = { fontSize: 19, ...semibold, ...lineHeight26 }
const h3 = {
  fontSize: 14,
  ...bold,
  ...lineHeight19,
  ...uppercase
}
const h4 = {
  fontSize: 14,
  ...semibold,
  ...lineHeight19
}

const t1 = { fontSize: 16, ...normal, ...lineHeight26 }
const t2 = { fontSize: 14, ...normal, ...lineHeight19 }
const t3 = { fontSize: 12, ...normal, ...lineHeight19 }
const t1bold = { fontSize: 16, ...medium, ...lineHeight22 }
const t2bold = { fontSize: 14, ...bold, ...lineHeight19 }

const h2button = { ...h2, color: '#4460D4' }

const styles = {
  h1,
  h12,
  h2,
  h3,
  h4,
  t1,
  t2,
  t3,
  t1bold,
  t2bold,
  h2button
}

const Typo = ({
  type,
  style,
  center,
  semibold: sb,
  right,
  translate,
  color,
  boldColor,
  markdown,
  italic,
  nowrap,
  className,
  onClick,
  children
}) => {
  const { t } = useTranslation()

  const textStyle = useMemo(
    () => ({
      ...styles[type],
      ...(center ? { textAlign: 'center' } : {}),
      ...(sb ? semibold : {}),
      ...(right ? { textAlign: 'right' } : {}),
      ...(color ? { color } : {}),
      ...(italic ? { fontStyle: 'italic' } : {}),
      ...(nowrap ? { whiteSpace: 'nowrap' } : {})
    }),
    [type, center, right, color, italic, nowrap, sb]
  )

  return (
    <div style={style} className={className} onClick={onClick}>
      {!markdown && (
        <div style={textStyle}>{translate ? t(children) : children}</div>
      )}
      {markdown && (
        <ReactMarkdown
          children={children} // eslint-disable-line
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ node, ...props }) => {
              return <p style={textStyle} {...props} />
            },
            strong: ({ node, ...props }) => {
              return (
                <strong
                  style={{
                    ...textStyle,
                    ...bold,
                    color: boldColor || undefined
                  }}
                  {...props}
                />
              )
            }
          }}
        />
      )}
    </div>
  )
}

Typo.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  center: PropTypes.bool,
  semibold: PropTypes.bool,
  right: PropTypes.bool,
  translate: PropTypes.bool,
  color: PropTypes.string,
  boldColor: PropTypes.string,
  markdown: PropTypes.bool,
  italic: PropTypes.bool,
  nowrap: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
}

Typo.defaultProps = {
  type: 'h1',
  style: {},
  center: false,
  semibold: false,
  right: false,
  translate: false,
  color: undefined,
  boldColor: undefined,
  markdown: false,
  italic: false,
  nowrap: false,
  className: undefined,
  onClick: undefined
}

export default Typo
