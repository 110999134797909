const Arrow = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <svg width={11} height={18} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M208.707 9.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 1 0-1.414 1.414L206.586 9l-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364ZM1.293 8.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L3.414 9l5.657-5.657A1 1 0 0 0 7.657 1.93L1.293 8.293ZM208 8H2v2h206V8Z'
        fill='#a0a0a0'
      />
    </svg>
    <div style={{ width: '100%', height: 2, background: '#a0a0a0' }}></div>
    <svg width={11} height={18} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.707 9.707a1 1 0 0 0 0-1.414L3.343 1.929A1 1 0 0 0 1.93 3.343L7.586 9l-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364Zm-207.414-1.414a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 1 0 1.414-1.414L-195.586 9l5.657-5.657a1 1 0 1 0-1.414-1.414l-6.364 6.364ZM9 8h-206v2H9V8Z'
        fill='#a0a0a0'
      />
    </svg>
  </div>
)

export default Arrow
