import { notifyBugsnag } from './bugsnag'

const setItem = (key, value) => {
  try {
    typeof window !== 'undefined' &&
      window.localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    // if (err.name === 'QuotaExceededError') {
    //  return
    // }

    notifyBugsnag(err)
  }
}

const getItem = (key) => {
  try {
    return (
      typeof window !== 'undefined' &&
      JSON.parse(window.localStorage.getItem(key))
    )
  } catch (err) {
    notifyBugsnag(err)
  }
}

const removeItem = (key) => {
  try {
    typeof window !== 'undefined' && window.localStorage.removeItem(key)
  } catch (err) {
    notifyBugsnag(err)
  }
}

export const localStorage = {
  setItem,
  getItem,
  removeItem
}
