import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { NODE_ENV } from './utils/helpers'
import { notifyBugsnag } from './utils/bugsnag'
import de from './i18n/de.json'
import en from './i18n/en.json'

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  common: {
    // use an empty object to detect not available keys
    translation: {}
  }
}

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'common',
    debug: NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    saveMissing: true,
    missingKeyHandler: (_, __, key) => {
      if (NODE_ENV === 'development') return

      notifyBugsnag(new Error(`i18n missing key - ${key}`))
    }
  })

export default i18n
