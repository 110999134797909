import PropTypes from 'prop-types'

import state_0 from '../../../assets/images/normal-distribution-curve/estimation-state-0.png'
import state_1 from '../../../assets/images/normal-distribution-curve/estimation-state-1.png'
import state_2 from '../../../assets/images/normal-distribution-curve/estimation-state-2.png'
import state_3 from '../../../assets/images/normal-distribution-curve/estimation-state-3.png'
import state_4 from '../../../assets/images/normal-distribution-curve/estimation-state-4.png'
import state_5 from '../../../assets/images/normal-distribution-curve/estimation-state-5.png'
import state_6 from '../../../assets/images/normal-distribution-curve/estimation-state-6.png'
import state_7 from '../../../assets/images/normal-distribution-curve/estimation-state-7.png'
import state_8 from '../../../assets/images/normal-distribution-curve/estimation-state-8.png'

const states = {
  state_0,
  state_1,
  state_2,
  state_3,
  state_4,
  state_5,
  state_6,
  state_7,
  state_8
}

const EstimationState = ({ estimation }) => {
  return <img src={states[`state_${estimation}`]} style={{}} />
}

EstimationState.propTypes = {
  estimation: PropTypes.number.isRequired
}

export default EstimationState
