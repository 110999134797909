import PropTypes from 'prop-types'

import slice_0 from '../../../assets/images/normal-distribution-curve/estimation-slice-0.png'
import slice_0_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-0_selected.png'
import slice_1 from '../../../assets/images/normal-distribution-curve/estimation-slice-1.png'
import slice_1_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-1_selected.png'
import slice_2 from '../../../assets/images/normal-distribution-curve/estimation-slice-2.png'
import slice_2_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-2_selected.png'
import slice_3 from '../../../assets/images/normal-distribution-curve/estimation-slice-3.png'
import slice_3_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-3_selected.png'
import slice_4 from '../../../assets/images/normal-distribution-curve/estimation-slice-4.png'
import slice_4_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-4_selected.png'
import slice_5 from '../../../assets/images/normal-distribution-curve/estimation-slice-5.png'
import slice_5_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-5_selected.png'
import slice_6 from '../../../assets/images/normal-distribution-curve/estimation-slice-6.png'
import slice_6_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-6_selected.png'
import slice_7 from '../../../assets/images/normal-distribution-curve/estimation-slice-7.png'
import slice_7_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-7_selected.png'
import slice_8 from '../../../assets/images/normal-distribution-curve/estimation-slice-8.png'
import slice_8_selected from '../../../assets/images/normal-distribution-curve/estimation-slice-8_selected.png'
import { classNames } from '../../../utils/helpers'
import Typo from '../../typo'

const slices = {
  slice_0,
  slice_0_selected,
  slice_1,
  slice_1_selected,
  slice_2,
  slice_2_selected,
  slice_3,
  slice_3_selected,
  slice_4,
  slice_4_selected,
  slice_5,
  slice_5_selected,
  slice_6,
  slice_6_selected,
  slice_7,
  slice_7_selected,
  slice_8,
  slice_8_selected
}

const PERCENTAGES = [4, 7, 12, 17, 20, 17, 12, 7, 4]

const EstimationSlices = ({ estimation, updateEstimation }) => (
  <div style={{ display: 'flex', position: 'relative' }}>
    {[0, 1, 2, 3, 4, 5, 6, 7, 8]
      .map((index) => ({
        key: `slice_${index}`,
        selected: estimation === index
      }))
      .map(({ key, selected }, index) => (
        <div
          key={index}
          className={classNames(
            'relative group cursor-pointer',
            selected ? 'opacity-100' : 'opacity-60 hover:opacity-80'
          )}
          style={{ width: 'calc(100% * 1/9)' }}
          onClick={() => updateEstimation(index)}
        >
          <img
            className='w-full h-full'
            src={slices[`${key}${selected ? '_selected' : ''}`]}
          />

          {selected ? null : (
            <div
              className={classNames(
                'absolute w-full h-7 justify-center items-center bottom-0',
                'hidden sm:group-hover:flex'
              )}
            >
              <Typo type='h3' color='white'>
                {PERCENTAGES[index] + '%'}
              </Typo>
            </div>
          )}
        </div>
      ))}
  </div>
)

EstimationSlices.propTypes = {
  estimation: PropTypes.number.isRequired,
  updateEstimation: PropTypes.func.isRequired,
  fixed: PropTypes.bool
}

export default EstimationSlices
