import { useState } from 'react'
import { motion, useAnimationControls } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Rating from '@mui/material/Rating'

import { mutation, query } from '../../graphql/api'
import Typo from '../typo'
import { trackEvent } from '../../utils/analytics'
import { sendSlackMessage } from '../../utils/slack'
import { useMount } from '../../hooks/use-mount'
import { useAnalyseStateContext } from '../../context/analyse-state-context'
import { useIsMobile } from '../../hooks/use-is-mobile'
const { version } = require('../../../package.json')

const Outro = () => {
  const { t } = useTranslation()
  const { state, categories, career, partner, invitation } =
    useAnalyseStateContext()

  const { title, description, description_2 } = categories[state]
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  const [feedback, setFeedback] = useState(null)

  const controls = useAnimationControls()

  const { isMobile } = useIsMobile()

  useMount(() => {
    controls.start({ opacity: 1 })
    query({
      query: 'getFeedback',
      variables: { id: invitation },
      authMode: 'AWS_IAM'
    }).then((feedback) => {
      if (feedback !== null) setFeedbackSubmitted(true)
    })
  })

  const partnerName = partner.dispay_name || partner.name

  const onPressSendFeedback = () => {
    const input = {
      id: invitation,
      rating: feedback.rating,
      ref_link: window?.location?.href || 'NOT_SET',
      rating_text: feedback.rating_text,
      context: 'AIVY_REQUIREMENTS_ANALYSIS',
      version,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
    mutation({
      mutation: 'createFeedback',
      input,
      authMode: 'AWS_IAM'
    }).then(() => {
      trackEvent('rating_submitted')
      setFeedbackSubmitted(true)
      sendSlackMessage({
        message: `Ein Experte von ${partnerName} hat folgendes Feedback hinterlassen. Rating: ${feedback.rating} - Text: ${feedback.rating_text}`
      })
    })
  }

  return (
    <motion.div
      style={{ opacity: 0 }}
      animate={controls}
      transition={{ duration: 1 }}
      className='flex justify-center items-center'
    >
      <div className='m-8 mb-32 md:m-16' style={{ maxWidth: 738 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typo type='h1' translate center>
            {title}
          </Typo>
        </div>
        <div
          style={{
            marginTop: 27,
            padding: isMobile ? undefined : '21px 35px',
            background: isMobile ? undefined : '#EBEBEB',
            borderRadius: 15
          }}
        >
          <Typo type='t1' markdown>
            {t(description, {
              career_title: career.title,
              partner_name: partnerName
            })}
          </Typo>
          <Typo type='t1' markdown style={{ marginTop: 16 }}>
            {t(description_2)}
          </Typo>
        </div>
        {feedbackSubmitted === false && (
          <div className='mt-8 md:mt-4 items-center flex flex-col'>
            <div className='max-w-sm'>
              <Typo type='t1' translate center>
                feedback.text
              </Typo>
            </div>
            <div className='mt-4'>
              <Rating
                name='simple-controlled'
                value={feedback?.rating}
                disabled={feedbackSubmitted}
                size='large'
                onChange={(_, newRating) => {
                  setFeedback({ ...feedback, rating: newRating })
                }}
              />
            </div>

            {feedback?.rating <= 2 &&
              feedback?.rating !== null &&
              feedbackSubmitted === false && (
                <div className='mt-4 w-full'>
                  <textarea
                    value={feedback?.rating_text}
                    onChange={(event) =>
                      setFeedback({
                        ...feedback,
                        rating_text: event.target.value
                      })
                    }
                    rows={4}
                    name='comment'
                    id='comment'
                    placeholder={t('feedback.textarea_placeholder')}
                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm text-gray-900 placeholder:text-sm placeholder:text-gray-700 placeholder:italic'
                  />
                  <div className='flex flex-row justify-between items-center mt-2'>
                    <Typo type='t3' translate>
                      feedback.anonym_text
                    </Typo>
                  </div>
                </div>
              )}
            <button
              type='submit'
              disabled={feedback === null}
              onClick={onPressSendFeedback}
              style={{ opacity: feedback === null ? 0.3 : 1 }}
              className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-5'
            >
              {t('feedback.send_button')}
            </button>
          </div>
        )}
        {feedbackSubmitted === true && (
          <div className='mt-4 w-full text-center'>
            <p className='text-sm text-green-700'>
              {t('feedback.send_success')}
            </p>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default Outro
