import { useMemo } from 'react'
import { useAnalyseStateContext } from '../context/analyse-state-context'

export const getDimensionsFromCategories = ({ categories }) => {
  const result = new Map()

  const personality = categories.PERSONALITY.dimensions
  const skills = categories.SKILLS.dimensions
  const interests = categories.INTERESTS.dimensions

  personality.forEach((dimension) => result.set(dimension.key, dimension))
  skills.forEach((dimension) => result.set(dimension.key, dimension))
  interests.forEach((dimension) => result.set(dimension.key, dimension))

  return result
}

export const useDimensions = () => {
  const { categories } = useAnalyseStateContext()

  const dimensions = useMemo(
    () => getDimensionsFromCategories({ categories }),
    [categories]
  )

  const keys = useMemo(() => Array.from(dimensions.keys()), [dimensions])

  return { dimensions, keys }
}
