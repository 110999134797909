import { Fragment, useMemo, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

import { classNames } from '../utils/helpers'
import Typo from './typo'

const LanguageSwitch = () => {
  const { i18n } = useTranslation()

  const languages = useMemo(
    () => [
      { title: 'language_switch.title_de', locale: 'de' },
      { title: 'language_switch.title_en', locale: 'en' }
    ],
    []
  )

  const [language, setLanguage] = useState(
    window?.localStorage?.getItem('language') || 'de'
  )

  const changeLanguage = ({ locale }) => {
    setLanguage(locale)
    i18n.changeLanguage(locale)
    window?.localStorage?.setItem('language', locale)
  }

  return (
    <Menu as='div' className='relative inline-block text-left'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className='bg-white text-sm font-medium text-gray-70'>
              <div className='bg-white'>
                <div className='flex flex-row items-center'>
                  <Typo style={{ marginRight: 2 }} type='t2'>
                    {language.toUpperCase()}
                  </Typo>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 text-brightBlue'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow bg-white focus:outline-none'
              >
                <div className=''>
                  {languages.map((item) => (
                    <Menu.Item key={item.locale}>
                      {() => {
                        const active = language === item.locale

                        return (
                          <div
                            onClick={() => changeLanguage(item)}
                            style={{
                              background: active ? '#4460D4' : undefined
                            }}
                            className='px-4 py-2 text-sm flex flex-row justify-between cursor-pointer'
                          >
                            <Typo
                              type='t2'
                              color={active ? 'white' : undefined}
                              translate
                            >
                              {item.title}
                            </Typo>
                            <CheckIcon
                              className={classNames(
                                active ? 'block text-white' : 'hidden',
                                'h-5 w-5'
                              )}
                            />
                          </div>
                        )
                      }}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  )
}

export default LanguageSwitch
