import { useEffect, useRef, useState } from 'react'
import { getI18n } from 'react-i18next'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'

import NavigationListItem from './navigation-list-item'
import Typo from '../../typo'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import { useDimensions } from '../../../hooks/use-dimensions'
import Footer from '../../footer'
import AivyIcon from '../../../assets/images/aivyicon.svg'

const Navigation = () => {
  const { language } = getI18n()
  const { state, partner, career, categories } = useAnalyseStateContext()

  const { isMobile } = useIsMobile()
  const { keys: analyseKeys } = useDimensions()

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const titleRef = useRef()
  const [titleHeight, setTitleHeight] = useState(0)

  useEffect(() => {
    setTitleHeight(titleRef.current.getBoundingClientRect().height)
  }, [])

  const getCategories = () => {
    return (
      <>
        <Typo type='h3' style={{ marginTop: 30 }}>
          {categories.PERSONALITY.name[language]}
        </Typo>
        <div style={{ marginTop: 10 }}>
          {categories.PERSONALITY.dimensions.map((dimension, index) => (
            <NavigationListItem
              key={index}
              dimension={dimension}
              setDisplayMobileMenu={setDisplayMobileMenu}
            />
          ))}
        </div>

        <Typo type='h3' style={{ marginTop: 30 }}>
          {categories.SKILLS.name[language]}
        </Typo>
        <div style={{ marginTop: 10 }}>
          {categories.SKILLS.dimensions.map((dimension, index) => (
            <NavigationListItem
              key={index}
              dimension={dimension}
              setDisplayMobileMenu={setDisplayMobileMenu}
            />
          ))}
        </div>

        <Typo style={{ marginTop: 30 }} type='h3'>
          {categories.INTERESTS.name[language]}
        </Typo>
        <div style={{ marginTop: 10 }}>
          {categories.INTERESTS.dimensions.map((dimension, index) => (
            <NavigationListItem
              key={index}
              dimension={dimension}
              setDisplayMobileMenu={setDisplayMobileMenu}
            />
          ))}
        </div>
      </>
    )
  }

  if (isMobile && analyseKeys.includes(state)) {
    return (
      <>
        <div
          onClick={() => setDisplayMobileMenu(true)}
          className='absolute left-4 top-4 cursor-pointer z-10'
          ref={titleRef}
        >
          <Bars3Icon className='w-8 h-8 text-gray-900' />
        </div>
        <AnimatePresence>
          {displayMobileMenu && (
            <motion.div
              key='mobile-menu'
              initial={{ left: '-100%' }}
              animate={{ left: 0 }}
              exit={{ left: '-100%' }}
              className='absolute flex flex-col px-8 w-full h-full top-0 left-0 bg-white z-20'
            >
              <div
                onClick={() => setDisplayMobileMenu(false)}
                className='absolute right-4 top-4 cursor-pointer z-10'
              >
                <XMarkIcon className='w-8 h-8 text-gray-900' />
              </div>
              <div style={{ paddingTop: 28 }}>
                <img
                  style={{ height: 40, width: 'auto' }}
                  src={partner.logo || AivyIcon}
                  alt=''
                />
                <Typo type='h2' style={{ marginTop: 16 }} translate>
                  global.title
                </Typo>
                <Typo type='t1' style={{ marginTop: 4 }}>
                  {career.title}
                </Typo>
              </div>
              {getCategories()}
              <div className='mt-8 w-full py-3'>
                <Footer />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  }

  return (
    <div style={{ padding: '0 30px 32px 30px' }}>
      <div
        ref={titleRef}
        style={{
          background: 'white',
          zIndex: 5,
          paddingTop: 20,
          paddingBottom: isMobile ? 0 : 36
        }}
      >
        <img
          style={{ height: 40, width: 'auto' }}
          src={partner.logo || AivyIcon}
          alt=''
        />
        <Typo type='h2' style={{ marginTop: 16 }} translate>
          global.title
        </Typo>
        <Typo type='t1' style={{ marginTop: 4 }}>
          {career.title}
        </Typo>
      </div>
      <div
        style={{
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
          height: `calc(100% - ${titleHeight}px)`,
          minHeight: `calc(832px - ${titleHeight}px)`,
          paddingLeft: 4,
          paddingRight: 3,
          paddingBottom: 32,
          paddingTop: 20,
          display:
            state.includes('INTRO') ||
            state === 'OUTRO' ||
            state.includes('OVERVIEW')
              ? 'none'
              : 'block'
        }}
      >
        {getCategories()}
      </div>
    </div>
  )
}

export default Navigation
