import { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { getI18n } from 'react-i18next'

import Typo from '../../typo'
import { trackEvent } from '../../../utils/analytics'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'

const NavigationListItem = ({ dimension, setDisplayMobileMenu }) => {
  const { language } = getI18n()
  const { key, property, seen } = dimension
  const { state, setNavigateToState } = useAnalyseStateContext()

  const ref = useRef()
  const active = useMemo(() => state === key, [state, key])

  return (
    <div
      ref={ref}
      style={{
        marginLeft: active ? -1.5 : 0,
        borderLeftWidth: active ? 5 : 2,
        borderLeftColor: 'black',
        opacity: active || seen ? 1 : 0.3,
        cursor: seen ? 'pointer' : 'default'
      }}
      onClick={
        seen
          ? () => {
              setDisplayMobileMenu(false)
              setNavigateToState(key)
              trackEvent('sidebar_navigation', { to_dimension: key })
            }
          : undefined
      }
    >
      {seen && !active && (
        <Typo type='t2' style={{ padding: '4px 0 4px 14px' }}>
          {property[language]}
        </Typo>
      )}
      {active && (
        <Typo type='t2bold' style={{ padding: '4px 0 4px 13px' }}>
          {property[language]}
        </Typo>
      )}
      {!seen && !active && (
        <Typo type='t2' style={{ padding: '4px 0 4px 14px' }}>
          {property[language]}
        </Typo>
      )}
    </div>
  )
}

NavigationListItem.propTypes = {
  dimension: PropTypes.object.isRequired,
  setDisplayMobileMenu: PropTypes.func.isRequired
}

NavigationListItem.defaultProps = {}

export default NavigationListItem
