export const BUILD_ENV = process.env.REACT_APP_USER_BRANCH || 'develop'
export const NODE_ENV = process.env.NODE_ENV

export const IMPORTANCIES = [0, 0.5, 1, 2]

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const generateUUIDV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
