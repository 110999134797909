import { useState } from 'react'
import { getI18n } from 'react-i18next'
import PropTypes from 'prop-types'

import EstimationState from './estimation-state'
import Typo from '../../typo'
import { trackEvent } from '../../../utils/analytics'
import { IMPORTANCIES, classNames } from '../../../utils/helpers'
import Modal from '../../modal'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import AnalyseInfoCard from '../analyse/analyse-info-card'

const OverviewItem = ({ dimension }) => {
  const { language } = getI18n()
  const { updateImportance } = useAnalyseStateContext()
  const { isMobile } = useIsMobile()

  const { key, estimations, estimation, importance } = dimension

  const [displayAnalyse, setDisplayAnalyse] = useState(false)

  const { estimationTranslation } = {
    estimationTranslation: estimations[estimation][language]
  }

  if (isMobile) {
    return (
      <>
        <div className='w-full flex flex-col mx-auto max-w-sm'>
          <Typo type='h2'>{dimension.property[language]}</Typo>
          <div
            onClick={() => setDisplayAnalyse(true)}
            className='mt-4 cursor-pointer'
          >
            <EstimationState estimation={estimation} />
          </div>
          <div className='h-2' />
          <Typo type='t2' markdown>
            {estimationTranslation || ''}
          </Typo>
          <div className='mt-2 flex items-center justify-center py-4 px-6 rounded-2xl bg-gray-100'>
            <div className='flex flex-col justify-center'>
              <Typo type='t2' translate>
                global.importance_question
              </Typo>
              <div className='mt-2 flex items-center'>
                {IMPORTANCIES.map((_, index) => {
                  const filled = importance >= index

                  return (
                    <div
                      key={index}
                      onClick={() => {
                        updateImportance({ key, value: index })

                        trackEvent('importance_selected', {
                          dimension: key,
                          importance: IMPORTANCIES[index]
                        })
                      }}
                      className='w-6 h-6 flex items-center justify-center rounded-full border-2 border-indigo-500 mr-0.5 cursor-pointer'
                    >
                      <div
                        className={classNames(
                          'w-5 h-5 rounded-full border-2 border-gray-50',
                          filled ? 'bg-indigo-500' : 'bg-gray-50'
                        )}
                      ></div>
                    </div>
                  )
                })}
                <Typo type='h4' translate style={{ marginLeft: 8 }}>
                  {
                    {
                      '-1': '',
                      0: 'global.importance_unimportant',
                      1: 'global.importance_less_important',
                      2: 'global.importance_important',
                      3: 'global.importance_very_important'
                    }[importance]
                  }
                </Typo>
              </div>
            </div>
          </div>
        </div>
        <Modal open={displayAnalyse} setOpen={setDisplayAnalyse}>
          <div className='h-12' />
          <div className='flex justify-center'>
            <AnalyseInfoCard currentDimension={dimension} />
          </div>
          <div className='h-16' />
        </Modal>
      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: 1024 }} className='flex relative'>
        <div className='xl:flex py-4 px-6 w-full rounded-l-2xl bg-gray-50'>
          <div
            onClick={() => setDisplayAnalyse(true)}
            className='mt-2 xl:mt-0 flex flex-col justify-center w-1/3 cursor-pointer'
          >
            <EstimationState estimation={estimation} />
          </div>
          <div className='mt-4 xl:mt-0 xl:ml-8 w-full xl:w-2/3 flex flex-col justify-center'>
            <Typo type='t2' markdown>
              {estimationTranslation || ''}
            </Typo>
          </div>
        </div>
        <div
          style={{ minWidth: 288 }}
          className='flex items-center justify-center py-4 px-6 rounded-r-2xl bg-gray-100'
        >
          <div className='flex flex-col justify-center'>
            <Typo type='t2' translate>
              global.importance_question
            </Typo>
            <div className='mt-2 flex items-center'>
              {IMPORTANCIES.map((_, index) => {
                const filled = importance >= index

                return (
                  <div
                    key={index}
                    onClick={() => {
                      updateImportance({ key, value: index })

                      trackEvent('importance_selected', {
                        dimension: key,
                        importance: IMPORTANCIES[index]
                      })
                    }}
                    className='w-6 h-6 flex items-center justify-center rounded-full border-2 border-indigo-500 mr-0.5 cursor-pointer'
                  >
                    <div
                      className={classNames(
                        'w-5 h-5 rounded-full border-2 border-gray-50',
                        filled ? 'bg-indigo-500' : 'bg-gray-50'
                      )}
                    ></div>
                  </div>
                )
              })}
              <Typo type='h4' translate style={{ marginLeft: 8 }}>
                {
                  {
                    '-1': '',
                    0: 'global.importance_unimportant',
                    1: 'global.importance_less_important',
                    2: 'global.importance_important',
                    3: 'global.importance_very_important'
                  }[importance]
                }
              </Typo>
            </div>
          </div>
        </div>
        <div className='absolute px-6 py-1' style={{ top: -20 }}>
          <Typo type='h2'>{dimension.property[language]}</Typo>
        </div>
      </div>
      <Modal open={displayAnalyse} setOpen={setDisplayAnalyse}>
        <div className='h-12' />
        <div className='flex justify-center'>
          <AnalyseInfoCard currentDimension={dimension} />
        </div>
        <div className='h-16' />
      </Modal>
    </>
  )
}

OverviewItem.propTypes = {
  dimension: PropTypes.object.isRequired
}

export default OverviewItem
