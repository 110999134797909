import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AivyIcon from '../assets/images/aivyicon.svg'
import { classNames } from '../utils/helpers'

const Maintenance = ({ state }) => {
  const { t } = useTranslation()

  const prefix = `${state || 'ERROR_UNHANDLED'}.`

  return (
    <>
      <div className='flex min-h-full flex-col bg-white mt-24 pt-16 pb-12'>
        <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
          <div className='flex flex-shrink-0 justify-center'>
            <a href='/' className='inline-flex'>
              <span className='sr-only'>aivy-icon</span>
              <img className='h-16 w-auto' src={AivyIcon} alt='' />
            </a>
          </div>
          <div className='pb-16 pt-8'>
            <div className='flex flex-col items-center text-center'>
              <h1
                className={classNames(
                  'mt-2 font-bold tracking-tight text-gray-900',
                  'text-4xl sm:text-5xl'
                )}
              >
                {t(prefix + 'title')}
              </h1>
              <p className='mt-4 max-w-md text-base text-gray-900'>
                {t(prefix + 'message')}
              </p>
            </div>
          </div>
        </main>

        <footer className='mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8'>
          <nav className='flex justify-center space-x-4'>
            <a
              href={`mailto:help@aivy.app?subject=[SUPPORT - AIVY-REQUIREMENTS-ANALYSIS]&body=(${window.location.href} - do not remove!)`}
              className='text-md font-medium text-indigo-500 hover:text-indigo-600'
            >
              {t(prefix + 'contact_support')}
            </a>
          </nav>
        </footer>
      </div>
    </>
  )
}

Maintenance.propTypes = {
  state: PropTypes.string
}

Maintenance.defaultProps = {
  state: undefined
}

export default Maintenance
