import { useEffect, useState } from 'react'
import { localStorage } from '../utils/local-storage'
import { isNil } from 'lodash'

export const usePersist = ({ key, initialState, skip }) => {
  const [data, setData] = useState(
    isNil(localStorage.getItem(key)) ? initialState : localStorage.getItem(key)
  )

  useEffect(() => {
    if (skip) return

    localStorage.setItem(key, data)
  }, [key, data, skip])

  return [data, setData]
}
