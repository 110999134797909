import * as React from 'react'
import { createRoot } from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './index.css'
import './i18n'
import App from './App'
import Maintenance from './components/maintenance'
import { BUILD_ENV, NODE_ENV } from './utils/helpers'
import { localStorage } from './utils/local-storage'

const { version } = require('../package.json')

Bugsnag.start({
  apiKey: '77f07a0ff6d0189879f1f60e9f298229',
  releaseStage: BUILD_ENV,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (NODE_ENV === 'development') return false

    const build_env = BUILD_ENV.toUpperCase()
    const partner_id = localStorage.getItem('partner-id')
    const partner_name = localStorage.getItem('partner-name')
    const career_id = localStorage.getItem('career-id')
    const career_analysis_id = localStorage.getItem('career-analysis-id')

    event.context = `version ${version}`
    event.addMetadata('meta', {
      version,
      build_env,
      partner_id,
      partner_name,
      career_id,
      career_analysis_id
    })
  }
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Maintenance}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
)
