import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ActivityIndicator from '../components/activity-indicator'
import { useQuery } from '../hooks/use-query'
import Navigation from '../components/main/navigation/navigation'
import { mutation, query } from '../graphql/api'
import { useCategories } from '../hooks/use-categories'
import Questionaire from '../components/main/questionaire'
import Maintenance from '../components/maintenance'
import { IMPORTANCIES, classNames } from '../utils/helpers'
import { newScoreCalc, oldScoreCalc } from '../utils/pnorm'
import { localStorage } from '../utils/local-storage'
import Footer from '../components/footer'
import LanguageSwitch from '../components/language-switch'
import { trackEvent } from '../utils/analytics'
import { AnalyseStateContextProvider } from '../context/analyse-state-context'
import { useIsMobile } from '../hooks/use-is-mobile'
import { getDimensionsFromCategories } from '../hooks/use-dimensions'
import { useMount } from '../hooks/use-mount'

const Main = () => {
  const invitation = useQuery('invitation')?.replace(/\s/g, '')
  const location = useLocation()

  const { isMobile } = useIsMobile()

  const [partner, setPartner] = useState(null)
  const [career, setCareer] = useState(null)
  const [careerAnalyse, setCareerAnalyse] = useState(null)

  const [state, setState] = useState('INITIAL')
  const [error, setError] = useState(null)
  const [navigateToState, setNavigateToState] = useState(null)

  const {
    isLoading: dataInPreparation,
    categories,
    time_needed_in_ms,
    setSeen,
    updateEstimation,
    updateImportance
  } = useCategories({ analyse: careerAnalyse })

  const finishAnalyse = useCallback(async () => {
    const userInput = {}

    const categoryKeys = ['PERSONALITY', 'SKILLS', 'INTERESTS']

    const dateToCheck = new Date(career.createdAt)
    const cutoffDate = new Date('2023-10-18')

    categoryKeys.forEach((key) => {
      categories[key].dimensions.forEach(({ key, estimation, importance }) => {
        userInput[key] = {
          score:
            dateToCheck < cutoffDate
              ? oldScoreCalc(estimation)
              : newScoreCalc(estimation),
          importance: IMPORTANCIES[importance]
        }
      })
    })

    const now = new Date()
    const last_interaction = new Date(localStorage.getItem('last-interaction'))

    const total_time_needed_in_ms =
      time_needed_in_ms + (now.getTime() - last_interaction.getTime())

    localStorage.setItem('last-interaction', now.toISOString())

    const input = {
      id: invitation,
      url_params: JSON.stringify(location),
      verified: true,
      status: 'COMPLETED',
      partner_id: partner.id,
      career_id: career.id,
      total_time_needed_in_min: Math.round(
        total_time_needed_in_ms / (1000 * 60)
      ),
      input: JSON.stringify(userInput)
    }

    trackEvent('analyse_finished', { state: 'OUTRO' })

    return mutation({
      mutation: 'updateCareerAnalyse',
      input,
      authMode: 'AWS_IAM'
    })
  }, [career, categories, invitation, location, partner, time_needed_in_ms])

  const context = useMemo(
    () => ({
      state,
      setState,
      setError,
      navigateToState,
      setNavigateToState,
      invitation,
      partner,
      career,
      categories,
      setSeen,
      updateEstimation,
      updateImportance,
      finishAnalyse
    }),
    [
      state,
      setState,
      setError,
      navigateToState,
      setNavigateToState,
      invitation,
      partner,
      career,
      categories,
      setSeen,
      updateEstimation,
      updateImportance,
      finishAnalyse
    ]
  )

  useMount(() => {
    if (!invitation) {
      setError('ERROR_NO_INVITATION')
      return
    }

    const initialize = async () => {
      const ca = await query({
        query: 'getCareerAnalyse',
        variables: { id: invitation },
        authMode: 'AWS_IAM'
      })

      if (!ca) {
        setError('CAREER_ANALYSE_NOT_FOUND')
        return
      }

      const { id: career_analysis_id, partner_id, career_id } = ca

      localStorage.setItem('career-analysis-id', career_analysis_id)
      localStorage.setItem('partner-id', partner_id)
      localStorage.setItem('career-id', career_id)

      trackEvent('launch')

      const [p, c] = await Promise.all([
        query({
          query: 'getPartner',
          variables: { id: partner_id },
          authMode: 'AWS_IAM'
        }),
        query({
          query: 'getCareer',
          variables: { id: career_id },
          authMode: 'AWS_IAM'
        })
      ])

      if (!c) {
        setError('CAREER_NOT_FOUND')
        return
      }

      setPartner(p)
      setCareer(c)
      setCareerAnalyse(ca)

      localStorage.setItem('partner-name', p.name)

      if (ca.status === 'COMPLETED') {
        setState('OUTRO')
        return
      }

      const getLastState = () => {
        const { seen } = JSON.parse(ca.input || '{}')._temp || {}

        if (!seen) return undefined
        return seen[seen.length - 1]
      }

      const lastState = getLastState()
      if (lastState) {
        setState(lastState)
        trackEvent('use_local_storage', { dimension: lastState })
        return
      }

      setState('INTRO_0')
    }

    initialize()

    // start the interaction on mount to track time needed
    localStorage.setItem('last-interaction', new Date().toISOString())
  })

  if (
    [
      'ERROR_NO_INVITATION',
      'ERROR_GET_DATA',
      'CAREER_ANALYSE_NOT_FOUND',
      'CAREER_NOT_FOUND',
      'ESTIMATION_UNSET_ERROR'
    ].includes(error)
  ) {
    return <Maintenance state={error} />
  }

  if (state === 'INITIAL' || dataInPreparation) {
    return (
      <div className='flex justify-center pt-64'>
        <ActivityIndicator />
      </div>
    )
  }

  if (isMobile) {
    const analyseKeys = Array.from(
      getDimensionsFromCategories({ categories }).keys()
    )

    return (
      <AnalyseStateContextProvider value={context}>
        <div
          className={classNames(
            'absolute h-10 right-4 top-0 pl-3 bg-white z-10',
            state.includes('INTRO') ? 'flex justify-end items-center' : 'hidden'
          )}
        >
          <LanguageSwitch />
        </div>
        <div className='flex flex-col h-screen'>
          <div className='mt-4'>
            <Navigation />
          </div>
          <div
            style={{
              ...(analyseKeys.includes(state) && {
                height: '100vh',
                minHeight: 640,
                overflow: 'hidden'
              })
            }}
          >
            <Questionaire />
          </div>
          {analyseKeys.includes(state) ? null : (
            <div className='mt-auto w-full px-8 py-3'>
              <Footer />
            </div>
          )}
        </div>
      </AnalyseStateContextProvider>
    )
  }

  return (
    <AnalyseStateContextProvider value={context}>
      <div className='fixed h-10 right-4 top-0 pl-3 flex justify-end items-center bg-white z-10'>
        <LanguageSwitch />
      </div>
      <div
        style={{
          width: 260,
          height: '100vh',
          minHeight: 768,
          float: 'left',
          overflow: 'auto'
        }}
      >
        <Navigation />
      </div>
      <div
        style={{
          height: '100vh',
          minHeight: 768,
          overflow: state === 'OVERVIEW' ? 'auto' : 'hidden'
        }}
      >
        <Questionaire />
      </div>
      <div className='fixed h-10 left-0 bottom-0 w-full px-4 flex justify-end items-center bg-white'>
        <Footer />
      </div>
    </AnalyseStateContextProvider>
  )
}

export default Main
