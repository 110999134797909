import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = (query) => {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(decodeURIComponent(search))

    return params.get(query)
  }, [query, search])
}
