export const FEEDBACK = `{
  id
  rating
  rating_text
  message
  context
  ref_link
  version
  createdAt
  updatedAt
  __typename
}`
