import { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const initial = {
  value: {}
}

const AnalyseStateContext = createContext({
  productTourState: initial,
  setProductTourState: () => undefined
})

AnalyseStateContext.displayName = 'AnalyseStateContext'

export function AnalyseStateContextProvider(props) {
  const value = useMemo(() => props.value, [props.value])

  return <AnalyseStateContext.Provider value={value} {...props} />
}

AnalyseStateContextProvider.propTypes = {
  value: PropTypes.object.isRequired
}

export function useAnalyseStateContext() {
  const context = useContext(AnalyseStateContext)

  if (!context) {
    throw new Error('useAnalyseStateContext must be used within a AppProvider')
  }

  return context
}
