import { CAREER_ANALYSE } from './schema/career-analyse'
import { FEEDBACK } from './schema/feedback'

export const updateCareerAnalyse = /* GraphQL */ `
  mutation UpdateCareerAnalyse(
    $input: UpdateCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    updateCareerAnalyse(input: $input, condition: $condition) ${CAREER_ANALYSE}
  }
`

export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) ${FEEDBACK}
  }
`
