import { useCallback, useEffect, useRef } from 'react'
import { motion, useAnimate, useAnimationControls } from 'framer-motion'
import { getI18n, useTranslation } from 'react-i18next'

import Carousel from '../carousel/carousel'
import Analyse from './analyse/analyse'
import Intro from './intro'
import { trackEvent } from '../../utils/analytics'
import Outro from './outro'
import Button from '../button'
import { useMount } from '../../hooks/use-mount'
import Overview from './overview/overview'
import OverviewIntro from './overview-intro'
import { useAnalyseStateContext } from '../../context/analyse-state-context'
import { useIsMobile } from '../../hooks/use-is-mobile'
import { useDimensions } from '../../hooks/use-dimensions'
import { notifyBugsnag } from '../../utils/bugsnag'
import { mutation } from '../../graphql/api'

const styles = {
  intro_outro: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const INTRO_KEYS = ['INTRO_0', 'INTRO_1', 'INTRO_2', 'INTRO_3', 'INTRO_4']

const Questionaire = () => {
  const { t } = useTranslation()
  const { language } = getI18n()
  const {
    state,
    invitation,
    setState,
    setError,
    navigateToState,
    setNavigateToState,
    setSeen
  } = useAnalyseStateContext()

  const carousel = useRef()
  const controls = useAnimationControls()

  const { isMobile } = useIsMobile()
  const { dimensions, keys: analyseKeys } = useDimensions()

  // useEffect(() => {
  //   console.log('rerender')
  // }, [analyseKeys])

  const [progress, animateProgress] = useAnimate()

  const navigate = useCallback(
    ({ toIndex }) => {
      controls
        .start({ opacity: 0, transition: { duration: 0 } })
        .then(() =>
          setTimeout(
            () => controls.start({ opacity: 1, transition: { duration: 1 } }),
            1000 * 0.2
          )
        )

      const toKey = analyseKeys[toIndex] || 'OVERVIEW_INTRO'

      setSeen(toKey)
      setState(toKey)
      trackEvent('navigation', { to_dimension: toKey })
      carousel.current.setIndex(toIndex)
    },
    [controls, analyseKeys, setSeen, setState]
  )

  useEffect(() => {
    if (!navigateToState) return

    const index = analyseKeys.indexOf(navigateToState)

    navigate({ toIndex: index })
    setNavigateToState(null)
  }, [navigateToState, setNavigateToState, analyseKeys, navigate])

  useEffect(() => {
    const index = analyseKeys.indexOf(state)

    if (index >= 0) {
      const toPercentage = ((index + 1) / analyseKeys.length) * 100

      animateProgress(
        progress.current,
        { width: `${toPercentage}%` },
        { duration: 1 }
      )
    }
  }, [state, progress, analyseKeys, animateProgress])

  useMount(() => {
    const index = analyseKeys.indexOf(state)

    // navigate only if index > 0 (could be -1 - out of range)
    if (index > 0) navigate({ toIndex: index })
  })

  if (INTRO_KEYS.includes(state)) {
    return (
      <div style={{ ...(!isMobile && styles.intro_outro) }}>
        <Intro
          states={INTRO_KEYS}
          onFinish={() => {
            const start_key = analyseKeys[0]
            trackEvent('start_analyse', { state: start_key })
            setState(start_key)
          }}
        />
      </div>
    )
  }

  if (['OVERVIEW_INTRO'].includes(state)) {
    return (
      <div style={{ ...(!isMobile && styles.intro_outro) }}>
        <OverviewIntro
          states={['OVERVIEW_INTRO']}
          onFinish={() => {
            const d_array = Array.from(dimensions.values())

            if (
              // check if all estimations are set
              d_array.some(({ estimation }) => estimation === -1)
            ) {
              // if not -> clear storage and start from scratch
              setError('ESTIMATION_UNSET_ERROR')
              mutation({
                mutation: 'updateCareerAnalyse',
                input: {
                  id: invitation,
                  input: null
                },
                authMode: 'AWS_IAM'
              })
              notifyBugsnag(
                new Error(
                  `ESTIMATION_UNSET_ERROR - ${JSON.stringify(
                    d_array.map(({ key, estimation }) => ({ key, estimation }))
                  )}`
                )
              )
              return
            }

            setState('OVERVIEW')
          }}
        />
      </div>
    )
  }

  if (state === 'OVERVIEW') {
    return (
      <div
        style={{
          ...(!isMobile && {
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '100%'
          })
        }}
      >
        <Overview />
      </div>
    )
  }

  if (state === 'OUTRO') {
    return (
      <div style={{ ...(!isMobile && styles.intro_outro) }}>
        <Outro />
      </div>
    )
  }

  return (
    <>
      <motion.div
        ref={progress}
        className='fixed top-0 left-0 right-0 h-2 w-0 z-10'
        style={{ background: '#4460D4' }}
      />
      <Carousel ref={carousel} range={analyseKeys.map((_, i) => i)}>
        {({ index }) => {
          const currentDimension = dimensions.get(analyseKeys[index])
          const previousDimension = dimensions.get(analyseKeys[index - 1])
          const nextDimension = dimensions.get(analyseKeys[index + 1])

          // index out of range
          if (!currentDimension) return null

          const analyseProps = {
            currentDimension,
            nextDimension,
            forward: () => navigate({ toIndex: index + 1 })
          }

          return (
            <div className='w-full h-full flex justify-center items-center'>
              {!!previousDimension && !isMobile && (
                <motion.div
                  className='absolute w-full top-0 left-0 px-16 flex flex-col items-center bg-white'
                  onClick={() => navigate({ toIndex: index - 1 })}
                  animate={controls}
                >
                  <div
                    style={{
                      padding: '21px 35px',
                      background: '#EBEBEB',
                      maxWidth: 738,
                      width: '100%',
                      height: 32,
                      cursor: 'pointer',
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15
                    }}
                  />
                  <div className='h-4 w-full cursor-pointer' />
                  <Button
                    type='back'
                    text={previousDimension.property[language]}
                    // on click is inside parent
                    onClick={() => undefined}
                  />
                </motion.div>
              )}

              {!!previousDimension && isMobile && (
                <motion.div
                  className='absolute w-full top-1 left-0 px-16 pb-1 flex flex-col items-center bg-white'
                  animate={controls}
                >
                  <Button
                    type='back'
                    text={t('global.back')}
                    onClick={() => navigate({ toIndex: index - 1 })}
                  />
                </motion.div>
              )}

              <Analyse {...analyseProps} />
            </div>
          )
        }}
      </Carousel>
    </>
  )
}

export default Questionaire
