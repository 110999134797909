import { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const initial = {
  system: {}
}

const SystemContext = createContext({
  productTourState: initial,
  setProductTourState: () => undefined
})

SystemContext.displayName = 'SystemContext'

export function SystemContextProvider(props) {
  const value = useMemo(() => props.system, [props.system])

  return <SystemContext.Provider value={value} {...props} />
}

SystemContextProvider.propTypes = {
  system: PropTypes.object.isRequired
}

export function useSystemContext() {
  const context = useContext(SystemContext)

  if (!context) {
    throw new Error('useSystemContext must be used within a AppProvider')
  }

  return context
}
