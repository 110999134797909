import { API, graphqlOperation } from 'aws-amplify'
import { isError } from 'lodash'

import * as queries from './queries'
import * as mutations from './mutations'

const convertGraphqlError = ({ errors }, { query }) => {
  if (
    // graphql error
    Array.isArray(errors)
  ) {
    const { message, path } = errors[0]
    const error_path = path && path[0] !== query && path[0]

    return new Error(
      [query, message, error_path, '(graphql error)']
        .filter((item) => item)
        .join(' / ')
    )
  }

  return new Error('convertGraphqlError: unknown')
}

export function query({ query, variables = {}, authMode }) {
  const promise = new Promise((resolve, reject) => {
    const possibleItems = []

    const request = (nextToken) => {
      API.graphql({
        ...graphqlOperation(queries[query], {
          limit: 10000, // can be overwritten by variables
          ...variables,
          nextToken
        }),
        authMode
      })
        .then(({ data }) => {
          if (!data[query]) {
            resolve(null)
            return
          }

          if (data[query].items) {
            const items = data[query].items
            const nextToken = data[query].nextToken

            possibleItems.push(...items)

            if (nextToken) request(nextToken)
            else resolve(possibleItems)
          } else {
            resolve(data[query])
          }
        })
        .catch((err) => {
          if (isError(err)) reject(err)
          else reject(convertGraphqlError(err, { query }))
        })
    }

    request()
  })

  return promise
}

export function mutation({ mutation, input, authMode }) {
  const promise = new Promise((resolve, reject) => {
    API.graphql({
      ...graphqlOperation(mutations[mutation], { input }),
      authMode
    })
      .then(({ data }) => resolve(data[mutation]))
      .catch((err) => {
        if (isError(err)) reject(err)
        else reject(convertGraphqlError(err, { query: mutation }))
      })
  })

  return promise
}
