import Typo from './typo'

const Footer = () => {
  return (
    <footer className='flex gap-x-6 justify-between md:justify-normal'>
      <div className='flex gap-x-2'>
        <a
          href='https://www.aivy.app/impressum'
          target='_blank'
          rel='noreferrer'
        >
          <Typo type='t2' translate>
            footer.imprint
          </Typo>
        </a>
        <Typo type='t2'>|</Typo>
        <a
          href='https://www.aivy.app/datenschutz'
          target='_blank'
          rel='noreferrer'
        >
          <Typo type='t2' translate>
            footer.data_protection
          </Typo>
        </a>
      </div>
      <Typo type='t2'>{`© ${new Date().getUTCFullYear()} Aivy GmbH`}</Typo>
    </footer>
  )
}
export default Footer
