import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { animate, motion, useMotionValue } from 'framer-motion'
import PropTypes from 'prop-types'

import Page from './page'

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
}

const transition = {
  type: 'spring',
  bounce: 0,
  duration: 0.4
}

// forwardRef
const Carousel = forwardRef(({ range, children }, ref) => {
  const containerRef = useRef(null)

  const y = useMotionValue(0)
  const [index, setIndex] = useState(0)

  useImperativeHandle(ref, () => {
    return {
      setIndex: (i) => setIndex(i)
    }
  })

  useEffect(() => {
    const controls = animate(
      y,
      -index * containerRef.current.clientHeight,
      transition
    )

    return () => {
      controls.stop()
    }
  }, [index, y])

  return (
    <motion.div ref={containerRef} style={containerStyle}>
      {range.map((index) => {
        return (
          <Page
            key={index}
            y={y}
            forward={() => setIndex(index + 1)}
            back={() => setIndex(index - 1)}
            index={index}
            renderPage={children}
          />
        )
      })}
    </motion.div>
  )
})

Carousel.propTypes = {
  range: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired
}

export default Carousel
