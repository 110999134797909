import { notifyBugsnag } from './bugsnag'
import { NODE_ENV } from './helpers'

const channels = {
  'kunden-controlling':
    'https://hooks.slack.com/services/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'
}

const _sendSlackMessage = (channel, message) => {
  return window.fetch(channels[channel], {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ text: message })
  })
}

export async function sendSlackMessage({
  channel = 'kunden-controlling',
  message
}) {
  // because of cors policy
  if (NODE_ENV === 'development') {
    return console.log('send-slack-message:', message) // eslint-disable-line
  }

  try {
    await _sendSlackMessage(channel, message)
  } catch (err) {
    notifyBugsnag(err)
  }
}
