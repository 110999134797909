import PropTypes from 'prop-types'

import Typo from './typo'
import chevron from '../assets/icons/chevron.png'

const ForwardButton = ({ text, translate }) => (
  <>
    <Typo type='h2button' translate={translate}>
      {text}
    </Typo>
    <img
      src={chevron}
      style={{ width: 8, height: 12, marginLeft: 8 }}
      alt='chevron right'
    />
  </>
)

ForwardButton.propTypes = {
  text: PropTypes.string.isRequired,
  translate: PropTypes.bool.isRequired
}

const BackButton = ({ text, translate }) => (
  <>
    <img
      src={chevron}
      style={{
        width: 8,
        height: 12,
        marginRight: 8,
        transform: 'rotate(180deg)'
      }}
      alt='chevron left'
    />
    <Typo type='h2button' translate={translate}>
      {text}
    </Typo>
  </>
)

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  translate: PropTypes.bool.isRequired
}

const ButtonType = ({ text, type, translate }) => {
  if (type === 'forward') {
    return <ForwardButton text={text} translate={translate} />
  }

  if (type === 'back') {
    return <BackButton text={text} translate={translate} />
  }

  return null
}

ButtonType.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  translate: PropTypes.bool.isRequired
}

const Button = ({ type, text, translate, disabled, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.4 : 1
      }}
      onClick={disabled ? undefined : onClick}
    >
      <ButtonType text={text} type={type} translate={translate} />
    </div>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

Button.defaultProps = {
  type: 'forward',
  translate: false,
  disabled: false
}

export default Button
