import { CAREER } from './schema/career'
import { CAREER_ANALYSE } from './schema/career-analyse'
import { FEEDBACK } from './schema/feedback'
import { PARTNER } from './schema/partner'
import { SYSTEM } from './schema/system'

export const getCareerAnalyse = /* GraphQL */ `
  query GetCareerAnalyse($id: ID!) {
    getCareerAnalyse(id: $id) ${CAREER_ANALYSE}
  }
`

export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) ${PARTNER}
  }
`

export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) ${CAREER}
  }
`

export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) ${SYSTEM}
  }
`

export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) ${FEEDBACK}
  }
`
