import { Normal } from 'lib-r-math.js'

export const { pnorm } = Normal()

// export const pnormFunc = (x) => {
//  return pnorm(x)
// }

export const oldScoreCalc = (estimation) =>
  Math.round(pnorm((estimation - 4) / 2) * 100)

export const newScoreCalc = (estimation) => 50 + (estimation - 4) * 12.5
