import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const pageStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%'
}

const Page = ({ index, renderPage, y, forward, back }) => {
  const child = useMemo(
    () => renderPage({ index, forward, back }),
    [index, renderPage, forward, back]
  )

  return (
    <motion.div
      style={{
        ...pageStyle,
        y,
        top: `${index * 100}%`,
        bottom: `${index * 100}%`
      }}
    >
      {child}
    </motion.div>
  )
}

Page.propTypes = {
  index: PropTypes.number.isRequired,
  renderPage: PropTypes.func.isRequired,
  y: PropTypes.object.isRequired,
  forward: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired
}

export default Page
