import Bugsnag from '@bugsnag/js'
import { NODE_ENV } from './helpers'

export const notifyBugsnag = (error) => {
  console.error(error) // eslint-disable-line

  if (NODE_ENV === 'development') return

  const { message } = error
  if (
    message.toLowerCase().includes('failed to fetch') ||
    message.toLowerCase().includes('network error') ||
    message.toLowerCase().includes('networkerror')
  ) {
    return
  }

  Bugsnag.notify(error)
}
