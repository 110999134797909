import PropTypes from 'prop-types'

const Layout = ({ children }) => {
  return children
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
